import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import i18n from "./utils/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";
import SuspenseContent from "./containers/SuspenseContent";
import checkAuth from "./app/auth";

// pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const EmailVerify = lazy(() => import("./pages/EmailVerifyPage"));

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const currentLanguage = i18n.language;
  return (
    <div className="language-switcher mr-5">
      {currentLanguage === "ar" ? (
        <button
          type="button"
          className="btn btn-xs btn-accent text-white"
          onClick={() => changeLanguage("en")}
        >
          English
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-xs btn-accent text-white"
          onClick={() => changeLanguage("ar")}
        >
          {t("arabic")}
        </button>
      )}
    </div>
  );
};

function App() {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    themeChange(false);
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }

    const checkAuthentication = async () => {
      const token = await checkAuth();
      setIsAuthenticated(!!token);
      setAuthChecked(true);
    };

    checkAuthentication();
  }, []);

  if (!authChecked) {
    return <SuspenseContent />; // Or a spinner/loader component
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/email-verify/:token" element={<EmailVerify />} />
          <Route path="/reset-password/:token" element={<EmailVerify />} />
          <Route path="/app/*" element={<Layout />} />
          <Route
            path="*"
            element={
              <Navigate to={isAuthenticated ? "/app/dashboard" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
